import React from "react"
import { graphql, navigate, withPrefix } from "gatsby"
import { getUserLangKey } from "ptz-i18n"

class RedirectLifestyle extends React.PureComponent {
  
  constructor(args) {
    
    super(args)

    if (typeof window !== "undefined") {
      const { langs, defaultLangKey } = args.data.site.siteMetadata.languages
      const langKey = getUserLangKey(langs, defaultLangKey)
      const url = withPrefix(`/${langKey}/lifestyle`)

      navigate(url)
    }
  }

  render() {
    return <div />
  }
}

export const pageQuery = graphql`
  query LifestyleQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`

export default RedirectLifestyle
